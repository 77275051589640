import React from 'react';

import { Router } from '@reach/router';
import { CvMainPage } from '../../templates/Cv-page/Cv-page';
import { CvSinglePage } from '../../templates/Cv-page/CvSingle-page';

const CvPage: React.FC = () => {
  return (
    <Router>
      <CvMainPage path="/Cv/" />
      <CvSinglePage path="/Cv/:id" />
    </Router>
  );
};

export default CvPage;
