export const cvLinks = [
  {
    id: 'CL461869',
    link: 'https://stgcvtstaffprofiler.blob.core.windows.net/public/CL461869.html',
  },
  {
    id: 'JG618690',
    link: 'https://stgcvtstaffprofiler.blob.core.windows.net/public/JG618690.html',
  },
  {
    id: 'JG954269',
    link: 'https://stgcvtstaffprofiler.blob.core.windows.net/public/JG954269.html',
  },
  {
    id: 'JW581440',
    link: 'https://stgcvtstaffprofiler.blob.core.windows.net/public/JW581440.html',
  },
];
