import React from 'react';
import { cvLinks } from './Cv-links';

export const CvSinglePage: React.FC = () => {
  const cvId = location.pathname.split('/').pop();
  const [selectedCV] = cvLinks.filter((ele: any) => ele.id === cvId);

  return (
    <>
      <iframe
        src={`${selectedCV.link}?timestamp=${Date.now()}`}
        title={selectedCV.id}
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          bottom: '0',
          right: '0',
          width: '100%',
          height: '100%',
          border: 'none',
          margin: '0',
          padding: '0',
        }}
      ></iframe>
    </>
  );
};
